
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { EMPTY, PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";
import NuclearUnitAutocomplete from "@/components/General/Autocompletes/Localisation/NuclearUnitAutocompleteWrapper.vue";

import { ServerType } from "prometheus-synced-ui";
import {
  HydraulicSystemDetails,
  HydraulicSystemGroupInfos,
  HydraulicSystemInfos,
  LITE_FEATURE,
  NuclearUnitResume,
} from "@/domain";

import {
  IHydraulicSystemGroupService,
  IHydraulicSystemService,
  INuclearUnitService,
} from "@/services";

@Component({
  components: {
    TableUi,
    NuclearUnitAutocomplete,
  },
})
export default class SystemsTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get hydraulicSystemService() {
    return this.container.resolve<IHydraulicSystemService>(
      S.HYDRAULIC_SYSTEM_SERVICE
    );
  }
  get hydraulicSystemGroupService() {
    return this.container.resolve<IHydraulicSystemGroupService>(
      S.HYDRAULIC_SYSTEM_GROUP_SERVICE
    );
  }
  get nuclearUnitService() {
    return this.container.resolve<INuclearUnitService>(S.NUCLEAR_UNIT_SERVICE);
  }

  get headers() {
    return [
      {
        text: "Nom",
        align: "center",
        sortable: true,
        value: "name",
      },
      {
        text: "Trigramme",
        align: "center",
        sortable: true,
        value: "trigram",
      },
      {
        text: "Groupe",
        align: "center",
        sortable: true,
        value: "group",
      },
      {
        text: "Tranche",
        align: "center",
        sortable: true,
        value: "nuclearUnit",
      },
      {
        text: "Actions",
        sortable: false,
        value: "actions",
        align: "center",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Localisation/SystemCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Localisation/SystemUpdate.vue");
  }

  get itemsFiltered() {
    if (this.nuclearUnitId == null) {
      return this.systems;
    } else {
      return _.filter(
        this.systems,
        (s) => s.nuclearUnitId == this.nuclearUnitId
      );
    }
  }

  loading: boolean = false;
  systems: HydraulicSystemInfos[] = [];
  groups: HydraulicSystemGroupInfos[] = [];
  nuclearUnitsResume: NuclearUnitResume[] = [];
  nuclearUnitId: string | null = null;

  mounted() {
    this.fetch();
  }

  async fetch() {
    this.loading = true;
    try {
      this.systems = await this.hydraulicSystemService.getMany({
        ids: [],
        nuclearUnitIds: [],
        includeEnabled: true,
        includeDisabled: true,
      });
      this.groups = await this.hydraulicSystemGroupService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
      this.nuclearUnitsResume = await this.nuclearUnitService.getResume();
    } finally {
      this.loading = false;
    }
  }

  create(system: HydraulicSystemDetails) {
    this.systems.push(HydraulicSystemInfos.fromDetails(system));
  }

  update(system: HydraulicSystemDetails) {
    const index = _.findIndex(this.systems, (s) => s.id == system.id);
    if (index != -1) {
      this.systems.splice(index, 1, HydraulicSystemInfos.fromDetails(system));
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const index = _.findIndex(this.systems, (s) => s.id == id);
      if (index != -1) {
        const system = this.systems[index];
        await this.hydraulicSystemService.updateStatus(id, {
          disabled: system.disabled,
        });
        system.disabled = !system.disabled;
        this.systems.splice(index, 1, system);
      }
    } finally {
      this.loading = false;
    }
  }

  getGroupLabel(groupId: string) {
    const index = _.findIndex(this.groups, (g) => g.id == groupId);
    return index != -1
      ? `${this.groups[index].name} (${this.groups[index].monogram})`
      : EMPTY;
  }

  getNuclearUnitResume(nuclearUnitId: string) {
    const index = _.findIndex(
      this.nuclearUnitsResume,
      (n) => n.id == nuclearUnitId
    );
    return index != -1 ? this.nuclearUnitsResume[index] : EMPTY;
  }
}
