
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { EMPTY, PROVIDER, SERVICES as S } from "@/config/literals";
import { DependencyContainer } from "tsyringe";

import {
  PowerPlantInfos,
  NuclearUnitInfos,
  NuclearUnitAutocomplete,
} from "prometheus-synced-ui";

import { INuclearUnitService, IPowerPlantService } from "@/services";

@Component({
  components: {
    NuclearUnitAutocomplete,
  },
})
export default class NuclearUnitAutocompleteWrapper extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: false, default: false })
  includeAll!: boolean;

  @Prop({ required: false, default: true })
  includeDisabled!: boolean;

  get nuclearUnitService() {
    return this.container.resolve<INuclearUnitService>(S.NUCLEAR_UNIT_SERVICE);
  }

  get powerPlantService() {
    return this.container.resolve<IPowerPlantService>(S.POWER_PLANT_SERVICE);
  }

  loading: boolean = false;
  powerPlants: PowerPlantInfos[] = [];
  nuclearUnits: NuclearUnitInfos[] = [];

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.nuclearUnits = await this.nuclearUnitService.getMany({
        ids: [],
        powerPlantIds: [],
        includeEnabled: true,
        includeDisabled: true,
      });
      this.powerPlants = await this.powerPlantService.getMany({
        ids: [],
        nuclearUnitIds: _.map(this.nuclearUnits, (u) => u.id),
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  filter(
    item: { id: string | null; name: string; powerPlant: string },
    queryText: string,
    itemText: string
  ) {
    const textOne = item.name.toLowerCase();
    const textTwo = item.powerPlant.toLowerCase();
    const searchText = queryText.toLowerCase();

    return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
  }
}
