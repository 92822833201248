
import { Component, Vue } from "vue-property-decorator";

import SystemsTable from "@/components/Core/Table/Localisation/SystemsTable.vue";

@Component({
  components: {
    SystemsTable,
  },
})
export default class Systems extends Vue {}
